import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Input,
  message,
  Popconfirm,
  Radio,
  Row,
  Typography,
  Upload,
  Divider,
  notification,
} from "antd";
import { CancelToken } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { PauseOutlined } from "@ant-design/icons";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import ReactQuill from "react-quill";
import { AuthContext } from "../../Auth";
import {
  useGetStatus,
  useSetCommentsAllowed,
  useSetEditingAllowed,
  useSetPinningAllowed,
  useSetPricingScheduleAvailable,
  useSetSoaVersion,
} from "../../redux/hooks/request";
import { COMPLETE_DRAWER_WIDTH, REQUEST_STATUS } from "../../utils/constants";
import useTriggerRequest from "../../api/request-trigger";
import { useGetModalValues, useSetModalValues } from "../../redux/hooks/modals";
import ApproveIcon from "../Icons/ApproveIcon";
import StartIcon from "../Icons/StartIcon";
import CompleteIcon from "../Icons/CompleteIcon";
import moment from "moment";
import { getRealm, getSoaId } from "../../utils/common";
import ApiUtils from "../../utils/apiUtils";
import UploadIcon from "../Icons/UploadIcon";
import { useQuery } from "react-query";
import {
  useLogIncompleteForm,
  useUpdateStatusDR,
} from "../../api/digital-request";
import { downloadFile } from "../../api/paraplanner/soaGeneration";
import { getAllSoa, getSoaById } from "../../axios/login";
import { useValidation } from "../../utils/validation";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "./index.css";
import { DollarInput } from "../Shared/Inputs";
import PricingUI from "../../containers/PricingUI";
import { useSelector } from "react-redux";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

const { Dragger } = Upload;

const dateFormat = "dddd, D MMMM yyyy";

const showInCompleteFormNoti = () => {
  notification.error({
    message: (
      <p style={{ marginBottom: 0 }} data-test="incomplete-form-error">
        Incomplete form
      </p>
    ),
    description:
      "Please complete all required fields to submit your digital request",
    style: {
      zIndex: 99999999999999,
    },
  });
};

const disabledDate = current => {
  return current && current < moment().startOf("day");
};

const postDocument = async (payload, config) => {
  const soaId = getSoaId();
  const { data } = await ApiUtils.DSRF_HTTPS.post(
    `/soa/${soaId}/supplementary`,
    payload,
    config
  );
  return data;
};

const FileUploader = ({ uploads, setUploads, ...restProps }) => {
  const onRemove = file => {
    setUploads(uploads.filter(q => q.uid !== file.uid));
  };

  const customRequest = async ({ file, onSuccess, onProgress, onError }) => {
    const data = new FormData();
    data.append("file", file, file.name);
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    return postDocument(data, config)
      .then(response => {
        onSuccess(response);
        message.success(`${file.name} was uploaded succesfully`);
      })
      .catch(err => {
        onError(err);
        message.error(`Failed to upload ${file.name}`);
      });
  };

  const onStatusChange = info => {
    setUploads(info.fileList || []);
  };

  return (
    <Dragger
      multiple
      fileList={uploads}
      customRequest={customRequest}
      onRemove={onRemove}
      onChange={onStatusChange}
      className="file-uploader"
      {...restProps}
    >
      <p>
        <UploadIcon />
      </p>
      <p className="ant-upload-text">
        Click or drag a file to this area to upload
      </p>
    </Dragger>
  );
};

const HoldActionButton = () => {
  const { validateRef } = useValidation();

  const setModalValues = useSetModalValues();

  const uiState = useSelector(state => state);
  const { mutate: logError } = useLogIncompleteForm();

  const openHoldDrawer = async () => {
    const error = await validateRef.current();
    if (error?.isError) {
      logError({
        data: {
          errorDetails: {
            ...error.tabs,
          },
          state: uiState,
        },
      });
      showInCompleteFormNoti();
    } else {
      setModalValues("holdDrawer", {
        open: true,
      });
    }
  };

  return (
    <>
      <Button
        icon={<PauseOutlined />}
        type="primary"
        className="black-btn"
        onClick={openHoldDrawer}
        data-test={`hold-form-btn`}
      >
        Hold
      </Button>
      <HoldDrawer />
    </>
  );
};

const HoldDrawer = () => {
  const history = useHistory();
  let { realm } = useParams();

  const setModalValues = useSetModalValues();
  const { holdReasons, open, selectedReasons } =
    useGetModalValues("holdDrawer");

  const [notes, setNotes] = useState("");

  const closeDrawer = () => {
    setModalValues("holdDrawer", {
      open: false,
    });
    setNotes("");
  };

  const [triggerRequest, { isLoading }] = useTriggerRequest();

  const handleTriggerRequest = async () => {
    const hide = message.loading("Submitting request", 0);

    triggerRequest({
      trigger: "hold",
      body: {
        parameters: {
          reason: [...selectedReasons],
          notes: notes,
        },
      },
    })
      .then(res => {
        message.success("Success");
        history.push(`/${realm}/request`);
      })
      .catch(e => {
        message.error("Whoops - no luck! We'll look into this");
      })
      .finally(() => {
        hide();
        closeDrawer();
      });
  };

  return (
    <Drawer
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Provide Hold Type
        </Typography.Title>
      }
      width={400}
      placement="right"
      closable={false}
      visible={open}
      style={{
        zIndex: 9999999999,
      }}
      onClose={closeDrawer}
    >
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Checkbox.Group
            value={selectedReasons}
            options={Object.keys(holdReasons).map(key => ({
              label: holdReasons[key],
              value: key,
            }))}
            className="reasons-drawer-checkbox-group"
            defaultValue={[]}
            onChange={val => {
              setModalValues("holdDrawer", {
                selectedReasons: val,
              });
            }}
          />
        </Col>
      </Row>
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Input.TextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
            autoSize={{ minRows: 4, maxRows: 6 }}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            disabled={!selectedReasons.length || isLoading}
            loading={isLoading}
            onClick={handleTriggerRequest}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

const ApproveActionButton = () => {
  const { validateRef } = useValidation();

  const setModalValues = useSetModalValues();

  const uiState = useSelector(state => state);
  const { mutate: logError } = useLogIncompleteForm();

  const openApproveDrawer = async () => {
    const error = await validateRef.current();
    if (error?.isError) {
      logError({
        data: {
          errorDetails: {
            ...error.tabs,
          },
          state: uiState,
        },
      });
      showInCompleteFormNoti();
    } else {
      setModalValues("approveDrawer", {
        open: true,
      });
    }
  };

  return (
    <>
      <Button
        icon={<ApproveIcon />}
        type="primary"
        className="advance-btn"
        onClick={openApproveDrawer}
        data-test={`advance-form-btn`}
      >
        Approve
      </Button>
      <ApproveDrawer />
    </>
  );
};

const ApproveDrawer = () => {
  const history = useHistory();
  let { realm } = useParams();

  const setModalValues = useSetModalValues();
  const [updateStatus] = useUpdateStatusDR();
  const { open } = useGetModalValues("approveDrawer");

  const [sendEmail, setSendEmail] = useState(true);
  const [attachPricingSchedule, setAttachPricingSchedule] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [useTemplate, setUseTemplate] = useState(true);
  const [completionDate, setCompletionDate] = useState(null);
  const [emailBody, setEmailBody] = useState("");

  const { data, isFetching } = useQuery(
    ["/approvalEmailBody", { completionDate }],
    ({}) => {
      const source = CancelToken.source();
      const promise = ApiUtils.DSRF_HTTPS.get(
        `/soa/${getSoaId()}?data=approvalEmailBody&completionDate=${completionDate
          ?.hour(12)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix()
          .toString()}`,
        { cancelToken: source.token }
      );

      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      enabled: !!completionDate,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {
        message.error(
          "Something went wrong fetching the approval email body..!!"
        );
      },
    }
  );

  const emailTemplate = data?.data?.data;

  const closeDrawer = () => {
    setModalValues("approveDrawer", {
      open: false,
    });
  };

  useEffect(() => {
    if (!sendEmail) {
      setAttachPricingSchedule(null);
      setUploads([]);
      setUseTemplate(null);
      setEmailBody(null);
    } else {
      setAttachPricingSchedule(false);
      setUseTemplate(true);
      setEmailBody("");
    }
  }, [sendEmail]);

  useEffect(() => {
    if (!useTemplate) {
      setEmailBody("");
    } else {
      if (emailTemplate) {
        setEmailBody(emailTemplate);
      }
    }
  }, [useTemplate, emailTemplate]);

  const [triggerRequest, { isLoading }] = useTriggerRequest();

  const handleTriggerRequest = async () => {
    const hide = message.loading("Submitting request", 0);

    triggerRequest({
      trigger: "advance",
      body: {
        parameters: {
          sendEmail,
          attachPricingSchedule,
          uploads: uploads.map(upload => upload.response.filepath),
          useTemplate,
          completionDate: completionDate
            ?.hour(12)
            .minute(0)
            .second(0)
            .millisecond(0)
            .unix()
            .toString(),
          emailBody,
        },
      },
    })
      .then(res => {
        message.success("Success");
        history.push(`/${realm}/request`);
        window.location.reload();
      })
      .catch(e => {
        message.error("Whoops - no luck! We'll look into this");
      })
      .finally(() => {
        hide();
        closeDrawer();
      });
  };

  const generateScheduleReport = () => {
    let soaId = getSoaId();
    let hide = message.loading("Generating Files...!", 0);
    updateStatus({
      soaId,
      action: "generate_approval_docs",
    }).then(res => {
      if (res?.data?.files) {
        res.data.files.forEach((file, idx) => {
          setTimeout(
            () => downloadFile(file, `${soaId}-${idx + 1}.docx`, true),
            500 * (idx + 1)
          );
        });
        hide();
      }
    });
  };

  const disableButton =
    !completionDate || (sendEmail && !emailBody) || isFetching;

  return (
    <Drawer
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Approval Confirmation
        </Typography.Title>
      }
      width={400}
      placement="right"
      closable={false}
      visible={open}
      style={{
        zIndex: 9999999999,
      }}
      onClose={closeDrawer}
    >
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Typography.Text className="mr-1" strong>
            Completion Date:{" "}
          </Typography.Text>
          <DatePicker
            disabledDate={disabledDate}
            placeholder="Please select date"
            format={dateFormat}
            value={completionDate}
            onChange={value => {
              if (value) {
                setCompletionDate(moment(value));
              } else {
                setCompletionDate(null);
              }
            }}
            popupStyle={{ zIndex: 99999999999 }}
          />
        </Col>
      </Row>
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Typography.Text className="mr-1" strong>
            Send Email:{" "}
          </Typography.Text>
          <Radio.Group
            name="sendEmail"
            value={sendEmail}
            onChange={e => {
              setSendEmail(e.target.value);
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Divider />
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Typography.Text className="mr-1" strong>
            Latest pricing schedule:{" "}
          </Typography.Text>

          <Radio.Group
            disabled={!sendEmail}
            name="attachPricingSchedule"
            value={attachPricingSchedule}
          >
            <Popconfirm
              disabled={!sendEmail}
              overlayStyle={{ zIndex: 99999999999 }}
              placement="top"
              title="Are you sure? This will overwrite the existing request and pricing schedule."
              onConfirm={() => setAttachPricingSchedule(true)}
            >
              <Radio value={true}>Yes</Radio>
            </Popconfirm>

            <Radio
              value={false}
              onClick={() => setAttachPricingSchedule(false)}
            >
              No
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Col span={24}>
          {!sendEmail ? (
            <Button disabled type="primary" className="black-btn width-100">
              Generate Request and Pricing schedule
            </Button>
          ) : (
            <Popconfirm
              overlayStyle={{ zIndex: 99999999999 }}
              placement="top"
              title="Are you sure? This will overwrite the existing request and pricing schedule."
              onConfirm={generateScheduleReport}
            >
              <Button type="primary" className="black-btn width-100">
                Generate Request and Pricing schedule
              </Button>
            </Popconfirm>
          )}
        </Col>
      </Row>
      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <FileUploader
            disabled={!sendEmail}
            uploads={uploads}
            setUploads={setUploads}
          />
        </Col>
      </Row>
      <Divider />

      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Typography.Text className="mr-1" strong>
            Email Body:{" "}
          </Typography.Text>
          <Radio.Group
            disabled={!sendEmail}
            name="useTemplate"
            value={useTemplate}
            onChange={e => {
              setUseTemplate(e.target.value);
            }}
          >
            <Radio value={true}>Template</Radio>
            <Radio value={false}>Custom</Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Input.TextArea
            disabled={!sendEmail}
            value={emailBody}
            onChange={e => setEmailBody(e.target.value)}
            autoSize={{ minRows: 10, maxRows: 16 }}
            style={{ width: "100%" }}
            placeholder="Type email here"
          />
        </Col>
      </Row>
      <Row justify="end" style={{ marginBottom: 12 }}>
        <Col>
          <Button
            type="primary"
            disabled={isLoading || disableButton}
            loading={isLoading}
            onClick={handleTriggerRequest}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

const CompleteActionButton = () => {
  const { validateRef } = useValidation();

  const setModalValues = useSetModalValues();

  const uiState = useSelector(state => state);
  const { mutate: logError } = useLogIncompleteForm();

  const openCompleteDrawer = async () => {
    const error = await validateRef.current();
    if (error?.isError) {
      logError({
        data: {
          errorDetails: {
            ...error.tabs,
          },
          state: uiState,
        },
      });
      showInCompleteFormNoti();
    } else {
      setModalValues("completeDrawer", {
        open: true,
      });
    }
  };

  return (
    <>
      <Button
        icon={<CompleteIcon />}
        type="primary"
        className="advance-btn"
        onClick={openCompleteDrawer}
        data-test={`advance-form-btn`}
      >
        Complete
      </Button>
      <CompleteDrawer />
    </>
  );
};

export const CompleteDrawer = ({ isPricingUI = false }) => {
  const history = useHistory();

  const setModalValues = useSetModalValues();
  const { open } = useGetModalValues("completeDrawer");

  const [uploads, setUploads] = useState([]);
  const [emailBody, setEmailBody] = useState("");
  const [sendEmail, setSendEmail] = useState(() =>
    isPricingUI ? false : true
  );
  const [pricingFinal, setPricingFinal] = useState(0);
  const [pricingComments, setPricingComments] = useState("");
  const [feesAdvisorI, setFeesAdvisorI] = useState(0);
  const [feesAdvisorO, setFeesAdvisorO] = useState(0);
  const [feesInsuranceI, setFeesInsuranceI] = useState(0);
  const [feesInsuranceO, setFeesInsuranceO] = useState(0);

  const [pricingFinalized, setPricingFinalized] = useState(false);
  const [emailPreview, setEmailPreview] = useState(false);

  // quill-delta-to-html declaration
  let quillRef = null;
  let reactQuillRef = null;

  useEffect(() => {
    if (reactQuillRef === null) return;
    if (typeof reactQuillRef.getEditor !== "function") return;

    quillRef = reactQuillRef.getEditor();
  });

  const closeDrawer = () => {
    setModalValues("completeDrawer", {
      open: false,
    });
  };
  const { refetch } = useQuery("/soa", getAllSoa, {
    refetchOnWindowFocus: false,
  });
  const [triggerRequest, { isLoading }] = useTriggerRequest();

  const handleTriggerRequest = async () => {
    const hide = message.loading("Submitting request", 0);

    //Converting HTML
    //get the contents of the editor as a Delta object
    const delta = quillRef.getContents();

    // Convert the Delta object to HTML format
    var configurations = {
      multiLineBlockquote: true,
      multiLineHeader: true,
      multiLineCode: true,
      encodeHtml: true,
      inlineStyles: {
        BOLD: { element: "b" },
        ITALIC: { element: "i" },
        UNDERLINE: { element: "u" },
        STRIKE: { element: "s" },
        CODE: { element: "code" },
      },
      blockStyles: {
        "header-1": { element: "h1" },
        "header-2": { element: "h2" },
        "header-3": { element: "h3" },
        "header-4": { element: "h4" },
        "header-5": { element: "h5" },
        "header-6": { element: "h6" },
        blockquote: { element: "blockquote" },
        code: { element: "code" },
        ordered_list: { element: "ol" },
        bullet_list: { element: "ul" },
        list_item: { element: "li" },
        paragraph: { element: "p" },
      },
      customTagAttributes: {
        a: {
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
    };

    var converter = new QuillDeltaToHtmlConverter(delta.ops, configurations);

    var html = converter.convert();

    // setEmailBody(html); //Even though set the html to emailBody it doesn't override the emailBody state, so sending html in the triggerRequest (body.parameters.emailBody)

    //if html consist a <ul> tag, then add a style to it
    if (html.includes("<ul>")) {
      html = html.replaceAll(
        "<ul>",
        "<ul style='list-style-position: inside; text-align: left;'>"
      );
    }
    if (html.includes("<ol>")) {
      html = html.replaceAll(
        "<ol>",
        "<ol style='list-style-position: inside; text-align: left;'>"
      );
    }

    triggerRequest({
      trigger: "advance",
      body: {
        parameters: {
          uploads: uploads.map(upload => upload.response.filepath),
          sendEmail: sendEmail,
          emailBody: html,
          fees: {
            advisor: {
              initial: feesAdvisorI,
              ongoing: feesAdvisorO,
            },
            insurance: {
              initial: feesInsuranceI,
              ongoing: feesInsuranceO,
            },
          },
          pricing: {
            final: pricingFinal,
            comments: pricingComments,
          },
        },
      },
    })
      .then(res => {
        message.success("Success");
        refetch();
        history.push(`/${getRealm()}/request`);
      })
      .catch(e => {
        message.error("Whoops - no luck! We'll look into this");
      })
      .finally(() => {
        hide();
        closeDrawer();
      });
  };

  const { data, isFetching } = useQuery(
    ["/completeEmailBody", { soaId: getSoaId() }],
    ({}) => {
      const source = CancelToken.source();
      const promise = ApiUtils.DSRF_HTTPS.get(
        `/soa/${getSoaId()}?data=completedEmailBody`,
        { cancelToken: source.token }
      );

      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      enabled: open,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {
        message.error(
          "Something went wrong fetching the complete email body..!!"
        );
      },
    }
  );

  const { data: pricingData, isFetching: isFetchingPricing } = useQuery(
    ["/pricing", { soaId: getSoaId() }],
    ({}) => {
      const source = CancelToken.source();
      const promise = ApiUtils.DSRF_HTTPS.get(`/soa/${getSoaId()}/pricing`, {
        cancelToken: source.token,
      });

      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      enabled: open,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {
        message.error("Something went wrong fetching the pricing..!!");
      },
    }
  );

  const emailTemplate = data?.data?.data;

  useEffect(() => {
    if (emailTemplate) {
      setEmailBody(emailTemplate);
    }
  }, [emailTemplate]);

  const costFloat = pricingData?.data?.data?.costFloat || 0;

  const disableButton =
    isFetching ||
    isFetchingPricing ||
    pricingFinal < 0 ||
    !emailBody ||
    !pricingFinalized;
  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <Drawer
      title={
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          Complete Request
        </Typography.Title>
      }
      width={COMPLETE_DRAWER_WIDTH}
      placement="right"
      closable={COMPLETE_DRAWER_WIDTH === "100%"}
      visible={open}
      style={{
        zIndex: 9999999999,
      }}
      onClose={closeDrawer}
      className="complete-drawer"
    >
      <Row>
        <Col span={12} style={{ borderRight: "1px solid #d9d9d9" }}>
          <PricingUI
            soaId={getSoaId()}
            readOnly={pricingFinalized}
            onSubmit={val => {
              setPricingFinalized(true);
              setPricingFinal(val);
            }}
            onPricingChange={setPricingFinal}
          />
        </Col>
        <Col span={12}>
          <Row justify="end" align="middle" style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Checkbox
                checked={sendEmail}
                onChange={e => setSendEmail(e.target.checked)}
              >
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Send Final SoA Email
                </Typography.Title>
              </Checkbox>
            </Col>
            <Col span={8}></Col>
            <Col span={4}>
              <Button
                type="default"
                loading={isLoading}
                onClick={() => {
                  if (emailPreview) setEmailPreview(false);
                  else setEmailPreview(true);
                }}
              >
                {emailPreview ? "Edit Email" : "Preview"}
              </Button>
            </Col>
          </Row>
          {!emailPreview ? (
            <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
              <Col span={24} style={{ position: "relative" }}>
                <ReactQuill
                  ref={el => {
                    reactQuillRef = el;
                  }}
                  theme="snow"
                  value={emailBody}
                  onChange={setEmailBody}
                  className="email-input"
                  placeholder="Type email here"
                  bounds={`[data-text-editor="form-editor"]`} //for link editor to be not cut-off
                  modules={{
                    toolbar: [
                      [
                        "bold",
                        "italic",
                        "underline",
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                      ],
                      [{ header: "1" }, { header: "2" }],
                      [{ size: [] }],
                    ],
                  }}
                />
                {isPricingUI && !sendEmail && (
                  <div className="disable-overlay" />
                )}
              </Col>
            </Row>
          ) : (
            <Row
              justify="end"
              align="middle"
              style={{ marginBottom: 12, padding: 10 }}
            >
              <Col span={24} style={{ position: "relative" }}>
                <ReactQuill
                  ref={el => {
                    reactQuillRef = el;
                  }}
                  theme="snow"
                  value={emailBody}
                  onChange={setEmailBody}
                  className="email-input"
                  placeholder="Type email here"
                  bounds={`[data-text-editor="form-editor"]`} //for link editor to be not cut-off
                  modules={{ toolbar: false }}
                  readOnly={true}
                  style={{ caretColor: "transparent" }}
                />
                {isPricingUI && !sendEmail && (
                  <div className="disable-overlay" />
                )}
              </Col>
              {/* <div 
                                id={'preview'} 
                                className={' ql-editor'}   
                                dangerouslySetInnerHTML={createMarkup(emailBody)}>
                            </div> */}
            </Row>
          )}
          <Row justify="end" align="middle" style={{ marginBottom: 12 }}>
            <Col span={24} style={{ position: "relative" }}>
              <FileUploader uploads={uploads} setUploads={setUploads} />
              {isPricingUI && !sendEmail && <div className="disable-overlay" />}
            </Col>
          </Row>

          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            Advisor Fee Confirmation
          </Typography.Title>
          <Row gutter={[16, 8]} className="fee-confirmation">
            <Col span={8} className="title-row">
              <Typography.Text>Fees</Typography.Text>
            </Col>
            <Col span={8} className="title-row">
              <Typography.Text>Total Advisor Fees</Typography.Text>
              <br />
              <small>
                <em>(Excluding Insurance)</em>
              </small>
            </Col>
            <Col span={8} className="title-row">
              <Typography.Text>Total Insurance Commissions</Typography.Text>
            </Col>

            <Col span={8}>
              <Typography.Text>Total Initial Fees</Typography.Text>
            </Col>
            <Col span={8}>
              <DollarInput value={feesAdvisorI} onChange={setFeesAdvisorI} />
            </Col>
            <Col span={8}>
              <DollarInput
                value={feesInsuranceI}
                onChange={setFeesInsuranceI}
              />
            </Col>

            <Col span={8}>
              <Typography.Text>Total Ongoing Fees</Typography.Text>
            </Col>
            <Col span={8}>
              <DollarInput value={feesAdvisorO} onChange={setFeesAdvisorO} />
            </Col>
            <Col span={8}>
              <DollarInput
                value={feesInsuranceO}
                onChange={setFeesInsuranceO}
              />
            </Col>
          </Row>

          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            SoA Pricing
          </Typography.Title>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Typography.Text>Current pricing (Exc GST)</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>${costFloat}</Typography.Text>
            </Col>
            <Col span={8} />

            <Col span={8}>
              <Typography.Text>Adjustment</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>
                {pricingFinal >= costFloat
                  ? `$${(pricingFinal - costFloat).toFixed(2)}`
                  : `-$${Math.abs(pricingFinal - costFloat).toFixed(2)}`}
              </Typography.Text>
            </Col>
            <Col span={8} />

            <Col span={8}>
              <Typography.Text>Final pricing (Exc GST)</Typography.Text>
            </Col>
            <Col span={8}>${pricingFinal}</Col>
            <Col span={8} />

            <Col span={8}>
              <Typography.Text>Pricing Comments</Typography.Text>
            </Col>
            <Col span={16}>
              <Input.TextArea
                value={pricingComments}
                onChange={e => setPricingComments(e.target.value)}
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 16 }}>
            <Col>
              <Button
                type="primary"
                disabled={isLoading || disableButton}
                loading={isLoading}
                onClick={handleTriggerRequest}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
};

const StartActionButton = () => {
  const [triggerRequest, { isLoading }] = useTriggerRequest();
  const {
    params: { realm, soaId },
  } = useRouteMatch({
    path: "/:realm/*/:soaId",
  });
  const history = useHistory();
  const setCommentsAllowed = useSetCommentsAllowed();
  const setEditingAllowed = useSetEditingAllowed();
  const setPinningAllowed = useSetPinningAllowed();
  const setPricingScheduleAvailable = useSetPricingScheduleAvailable();
  const setSoaVersion = useSetSoaVersion();

  const { refetch: getSoa } = useQuery([`/soa`, { soaId }], getSoaById, {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onSuccess: data => {
      setCommentsAllowed(data.commentsAllowed);
      setEditingAllowed(data.editingAllowed);
      setPinningAllowed(data.pinningAllowed);
      setPricingScheduleAvailable(data.pricingScheduleAvailable);
      setSoaVersion(data.version);
    },
    onError: () => {
      localStorage.removeItem("soaId");
      localStorage.removeItem("advisorId");
      localStorage.removeItem("soaVersion");
      message.error("Whoops - no luck! We'll look into this", 2.5);
      history.push(`/${realm}/request`);
    },
  });

  const handleTriggerRequest = async (collectFeedback = true) => {
    const hide = message.loading("Submitting request", 0);

    triggerRequest({ trigger: "advance" })
      .then(res => {
        message.success("Success");
        getSoa();
      })
      .catch(e => {
        message.error("Whoops - no luck! We'll look into this");
      })
      .finally(() => {
        hide();
      });
  };
  return (
    <Button
      disabled={isLoading}
      icon={<StartIcon />}
      type="primary"
      className="advance-btn"
      onClick={handleTriggerRequest}
      loading={isLoading}
      data-test={`advance-form-btn`}
    >
      Start Review
    </Button>
  );
};

const ParaplannerBanner = () => {
  const match = useRouteMatch("/:realm/*/:soaId");
  const isRequest = Boolean(match);

  const {
    parsedToken: {
      realm_access: { roles },
    },
  } = useContext(AuthContext);
  const isParaplanner = roles && roles.includes("paraplanner");

  const status = useGetStatus();

  const needsAttention = status === REQUEST_STATUS.inReview_needsAttnPL;
  const isSubmitted = status === REQUEST_STATUS.submitted;
  const needsAttentionExt = status === REQUEST_STATUS.inReview_needsAttnExt;
  const isInReview = status === REQUEST_STATUS.inReview_reviewing;
  const isProcessing = status === REQUEST_STATUS.processing;

  if (isRequest && isParaplanner) {
    if (needsAttention || isSubmitted) {
      return (
        <Alert
          className={`request-banner paraplanner-request-banner bg-warning`}
          message={
            <>
              <Typography.Text>
                To start the review process, please click
                <strong>
                  "
                  <span style={{ textDecoration: "underline" }}>
                    Start Review
                  </span>
                  "
                </strong>{" "}
              </Typography.Text>

              <div className="request-banner-actions">
                <StartActionButton />
              </div>
            </>
          }
          closable={false}
        />
      );
    }

    if (needsAttentionExt) {
      return (
        <Alert
          className={`request-banner paraplanner-request-banner bg-warning`}
          message={
            <>
              <Typography.Text>
                This request is currently
                <strong>
                  "<span style={{ textDecoration: "underline" }}>On Hold</span>"
                </strong>
                <br />
                Await the Advisers response before leaving additional comments
                unless it is urgent
              </Typography.Text>
            </>
          }
          closable={false}
        />
      );
    }

    if (isInReview) {
      return (
        <>
          <Alert
            className="request-banner paraplanner-request-banner"
            message={
              <>
                <Typography.Text>
                  If all the required information is provided, click{" "}
                  <strong>
                    "
                    <span style={{ textDecoration: "underline" }}>Approve</span>
                    "
                  </strong>{" "}
                  <br />
                  If you have any queries click{" "}
                  <strong>
                    "<span style={{ textDecoration: "underline" }}>Hold</span>"
                  </strong>{" "}
                </Typography.Text>

                <div className="request-banner-actions">
                  <HoldActionButton />
                  <ApproveActionButton />
                </div>
              </>
            }
            closable={false}
          />
        </>
      );
    }

    if (isProcessing) {
      return (
        <>
          <Alert
            className="request-banner paraplanner-request-banner"
            message={
              <>
                {/* <Typography.Text>
                  If you have any question please make relavant comments.
                  <br />
                  You may click{" "}
                  <strong>
                    "<span style={{ textDecoration: "underline" }}>Hold</span>"
                  </strong>{" "}
                  button to stop timer until you get feedback from the advisor.
                </Typography.Text> */}

                <Typography.Text>
                  If you have any further queries click{" "}
                  <strong>
                    "<span style={{ textDecoration: "underline" }}>Hold</span>"
                  </strong>{" "}
                </Typography.Text>

                <div className="request-banner-actions">
                  <HoldActionButton />
                  <CompleteActionButton />
                </div>
              </>
            }
            closable={false}
          />
        </>
      );
    }
  }

  return null;
};

export default ParaplannerBanner;
