import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Row, Col } from "antd";
import { SendOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetModalValues } from "../../redux/hooks/modals";
import { shouldAlignRight } from "./CommentsList";
import { AuthContext } from "../../Auth";

const { TextArea } = Input;

const getReplyCaption = type => {
  switch (type) {
    case "approve":
      return "Provide approval comment";
    case "reject":
      return "Provide rejection comment";
    default:
      return "Replying to";
  }
};

const AddComment = React.memo(
  ({
    handleAddComment,
    readOnly,
    inputRef,
    editCommentId,
    handleEditComment,
    replyToCommentId,
    setReplyToComment,
    replyToCommentType,
    handleAddFiles,
    filesPreview,
    setFilesPreview
  }) => {
    const { comments, open } = useGetModalValues("commentsDrawer");

    const [commentInput, setCommentInput] = useState(null);

    useEffect(() => {
      if (!open) {
        setCommentInput(null);
      }
    }, [open]);

    useEffect(() => {
      if (comments && editCommentId) {
        const comment = comments.find(c => c.commentId === editCommentId);
        setCommentInput(comment.content);
      }
    }, [comments, editCommentId]);

    const handleChange = event => {
      setCommentInput(event.target.value);
    };

    const handleSubmit = () => {
      if (commentInput === null && filesPreview.length === 0) {
        return;
      }
      if (editCommentId) {
        handleEditComment({ content: commentInput, commentId: editCommentId });
      } else {
        handleAddComment(commentInput);
      }

      setCommentInput(null);
    };

    const handleKeyDown = e => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit();
        setCommentInput(null);
      }
    };

    const handlePaste = e => {
      if (e.clipboardData.files.length === 0) {
        return;
      }
      const fileList = Array.from(e.clipboardData.files);
      setFilesPreview([...filesPreview, ...fileList]);
      // handleAddFiles(e.clipboardData.files);
    };

    const replyToComment = comments?.find(
      c => c.commentId === replyToCommentId
    );

    const {
      parsedToken: {
        sub,
        realm_access: { roles },
      },
    } = useContext(AuthContext);

    const { stakeholders } = useGetModalValues("commentsDrawer");

    const isParaplanner = roles && roles.includes("paraplanner");

    const isOwner = sub === replyToComment?.owner?.id;

    const alignRight = shouldAlignRight({
      stakeholders,
      ownerId: replyToComment?.owner?.id,
      userIsOwner: isOwner,
      userIsParaplanner: isParaplanner,
    });

    const showName = isParaplanner || alignRight;

    const name = showName ? replyToComment?.owner?.name : "PL Team";

    return (
      <>
        {replyToCommentId && (
          <Row className="reply-to-comment">
            <div className="header">
              <p>{getReplyCaption(replyToCommentType)}</p>
              <CloseOutlined
                onClick={() => setReplyToComment({ id: "", type: "" })}
              />
            </div>
            <div className="comment">
              <p className="author">{name}</p>
              <p className="content">{replyToComment.content}</p>
            </div>
          </Row>
        )}
        <div className="input-section">
          <div className="sub-input-section">
            <TextArea
              ref={inputRef}
              disabled={readOnly}
              autoSize={{ minRows: 3, maxRows: 3 }}
              onChange={handleChange}
              value={commentInput}
              placeholder="Type your comment here"
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
            />
            <Button
              disabled={readOnly}
              onClick={handleSubmit}
              type="primary"
              shape="circle"
              icon={<SendOutlined />}
              size={"large"}
              style={{ paddingTop: 6, paddingLeft: 3, marginLeft: 13 }}
            />
          </div>
          <Row>
            <small>
              <em>Enter</em> to send message, <em>Shift + Enter</em> for
              newline.
            </small>
          </Row>
        </div>
      </>
    );
  }
);

export default AddComment;
