import React from "react";

const ReplyIcon = () => {
  return (
    <svg
      width="15px"
      height="14px"
      viewBox="0 0 15 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Reply</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="6-copy-2"
          transform="translate(-1177.000000, -82.000000)"
          fill="#1192E8"
        >
          <g
            id="Actions-/-Operations-/-reply-/-24"
            transform="translate(1177.000000, 81.000000)"
          >
            <path
              d="M14.44,15.0000486 C14.258876,15.0025213 14.0905532,14.9068833 14,14.75 C12.6452872,12.4421667 10.1760104,11.017584 7.5,11 L7.5,14 C7.49899243,14.2018509 7.37671275,14.3832982 7.19,14.46 C7.00380145,14.5382984 6.78878035,14.4968723 6.645,14.355 L0.645,8.355 C0.550343675,8.2611167 0.497100838,8.13331877 0.497100838,8 C0.497100838,7.86668123 0.550343675,7.7388833 0.645,7.645 L6.645,1.645 C6.78878035,1.50312772 7.00380145,1.46170163 7.19,1.54 C7.37671275,1.6167018 7.49899243,1.79814906 7.5,2 L7.5,5.055 C11.7789125,5.60459197 14.9874679,9.24095478 15,13.555 C14.9988159,13.8893493 14.9771084,14.2233107 14.935,14.555 C14.9093896,14.7683103 14.7503748,14.941415 14.54,14.985 L14.44,15.0000486 Z M7.25,10 C9.83082697,9.96293455 12.2980924,11.0594969 14,13 C13.6892575,9.25324101 10.7036733,6.28672726 6.955,6 C6.69656812,5.97664851 6.49894714,5.7594826 6.5,5.5 L6.5,3.205 L1.705,8 L6.5,12.795 L6.5,10.5 C6.5,10.2238576 6.72385763,10 7,10 L7.27,10 L7.25,10 Z"
              id="Fill"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ReplyIcon;
