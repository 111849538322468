import React, { useState } from "react";
import {
  Button,
  Col,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Typography,
  Popconfirm,
} from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiUtils from "../../utils/apiUtils";
import "./index.css";
import { useEffect } from "react";
import PlanlogicLogo from "../../assets/planlogic.png";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";

const GST_RATE = 0.1;

const getPricingData = async ({ queryKey }) => {
  const { soaId } = queryKey[1];
  const { data } = await ApiUtils.DSRF_HTTPS.get(
    `/soa/${soaId}?data=pricing_schedule`
  );
  return data.data;
};

const putPricingData = async ({ soaId, body }) => {
  const { data } = await ApiUtils.DSRF_HTTPS.put(
    `/soa/${soaId}?action=update_pricing_schedule`,
    body
  );
  return data.data;
};

const getModifierTypes = modifier => {
  return [
    modifier.id.includes("surcharge") ? "surcharge" : "discount",
    modifier.id.includes("Dollar") ? "dollar" : "percentage",
  ];
};

const PricingUI = ({
  readOnly,
  soaId,
  onSubmit: onSubmitProp,
  onPricingChange: onPricingChangeProp,
  showSubmit = true,
}) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    ["pricing_schedule", { soaId }],
    getPricingData,
    { enabled: Boolean(soaId) }
  );

  const { isLoading: isMutating, mutateAsync } = useMutation(putPricingData, {
    onSuccess: data => {
      queryClient.setQueryData(["pricing_schedule", { soaId }], data);
    },
    onError: () => {
      message.error("Whoops, something went wrong...");
    },
  });
  const {
    isEditable,
    header,
    serviceOfferingOptions,
    sections,
    modifiers,
    billTo,
    otherPricingInfo,
  } = data || {};
  const disabled = isMutating || isLoading || !isEditable || readOnly;

  const coreStrategyTitle = "Core Strategies";
  const supplementaryStrategyTitle = "Supplementary Strategies";

  const [otherPricingInfomation, setOtherPricingInfo] = useState(
    otherPricingInfo ||
      `<p>
      <b>${coreStrategyTitle}</b>
      <ol>
      <li> </li>
      <li> </li>
      <li> </li>
      </ol>
    </p>
    <p>
      <b>${supplementaryStrategyTitle}</b>
      <ol>
      <li> </li>
      <li> </li>
      <li> </li>
      </ol>
    </p>
    `
  );
  const [totalPriceExGst, setTotalPriceExGst] = React.useState(0);
  const [finalPriceExGst, setFinalPriceExGst] = React.useState(0);
  const [gst, setGst] = React.useState(0);
  const [finalPrice, setFinalPrice] = React.useState(0);

  useEffect(() => {
    if (data) {
      let totalPrice = data.header.serviceOffering.line_items[0].unit_cost;

      data.sections.forEach(section => {
        if (section.visible) {
          section.line_items.forEach(item => {
            if (item.visible) {
              totalPrice += item.unit_cost * item.quantity;
            }
          });
        }
      });

      setTotalPriceExGst(totalPrice);

      let finalPrice = totalPrice;

      data.modifiers.forEach(modifier => {
        if (modifier.visible) {
          const types = getModifierTypes(modifier);
          if (types[0] === "surcharge") {
            if (types[1] === "dollar") {
              finalPrice += modifier.quantity || 0;
            } else {
              finalPrice += totalPrice * ((modifier.quantity || 0) / 100);
            }
          } else {
            if (types[1] === "dollar") {
              finalPrice -= modifier.quantity || 0;
            } else {
              finalPrice -= totalPrice * ((modifier.quantity || 0) / 100);
            }
          }
        }
      });

      setFinalPriceExGst(finalPrice);

      let gst = finalPrice * GST_RATE;

      setGst(gst);
      setFinalPrice(finalPrice + gst);

      if (onPricingChangeProp) {
        onPricingChangeProp(finalPrice);
      }

      if (data.otherPricingInfo) {
        setOtherPricingInfo(data.otherPricingInfo);
      }
    }
  }, [data]);

  if (isLoading || !data) {
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    );
  }

  if (isError) {
    return (
      <Typography.Text
        style={{
          color: "red",
          display: "grid",
          alignItems: "center",
          gap: 8,
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <PlusCircleOutlined
          style={{ fontSize: 24, transform: "rotate(45deg)" }}
        />
        Couldn't load the pricing UI
      </Typography.Text>
    );
  }

  const onServiceOfferingChange = value => {
    const body = {
      serviceOffering: value,
      totalPriceExGst,
      finalPriceExGst,
    };

    sections.forEach(section => {
      section.line_items.forEach(item => {
        body[item.id] = item.quantity;
      });
    });

    modifiers.forEach(modifier => {
      body[modifier.id] = modifier.quantity || 0;
    });

    const hide = message.loading("Syncing data...", 0);

    mutateAsync({
      soaId,
      body,
    })
      .then(() => {
        hide();
        message.success("Done");
      })
      .catch(() => {
        hide();
      });
  };

  const onSectionItemQuantityChange = (quantity, itemId) => {
    queryClient.setQueryData(["pricing_schedule", { soaId }], oldData => ({
      ...oldData,
      sections: oldData.sections.map(section => {
        return {
          ...section,
          line_items: section.line_items.map(item => {
            if (item.id === itemId) {
              return {
                ...item,
                quantity: quantity || 0,
              };
            }
            return item;
          }),
        };
      }),
    }));
  };

  const onModifierItemQuantityChange = (quantity, modifierId) => {
    queryClient.setQueryData(["pricing_schedule", { soaId }], oldData => ({
      ...oldData,
      modifiers: oldData.modifiers.map(modifier => {
        if (modifier.id === modifierId) {
          return {
            ...modifier,
            quantity: quantity || 0,
          };
        }
        return modifier;
      }),
    }));
  };

  const formatPricingInfoContent = otherPricingInfomation => {
    const parser = new DOMParser();
    const pricingInfoContent = parser.parseFromString(
      otherPricingInfomation,
      "text/html"
    );
    const elements = pricingInfoContent.getElementsByTagName("*");

    for (let element of elements) {
      if (
        element.textContent.trim().toLowerCase() ==
        coreStrategyTitle.toLowerCase()
      ) {
        element.classList.add("info-title");
      }
      if (
        element.textContent.trim().toLowerCase() ==
        supplementaryStrategyTitle.toLocaleLowerCase()
      ) {
        element.classList.add("info-title");
      }
    }
    return pricingInfoContent.body.innerHTML;
  };

  const onSubmit = () => {
    const body = {
      serviceOffering: header.serviceOffering.line_items[0].key,
      totalPriceExGst,
      finalPriceExGst,
    };

    sections.forEach(section => {
      section.line_items.forEach(item => {
        body[item.id] = item.quantity || 0;
      });
    });

    modifiers.forEach(modifier => {
      body[modifier.id] = modifier.quantity || 0;
    });

    // Add required classes to pricing information
    // Because react quill does not preserve some html data.
    body["otherPricingInfo"] = formatPricingInfoContent(otherPricingInfomation);

    const hide = message.loading("Syncing data...", 0);

    mutateAsync({
      soaId,
      body,
    })
      .then(() => {
        hide();
        message.success("Done");
        if (onSubmitProp) {
          onSubmitProp(finalPriceExGst);
        }
      })
      .catch(() => {
        hide();
        message.error("Error");
      });
  };

  const modifiersVisible = modifiers.filter(m => m.visible).length > 0;

  return (
    <>
      <Row align="bottom" justify="space-between" className="mb-3">
        <Col>
          <Typography.Text>
            <strong>Client Name: </strong>
            {billTo.clientName}
          </Typography.Text>
          <br />
          <Typography.Text>
            <strong>Advisor Name: </strong>
            {billTo.advisorName}
          </Typography.Text>
          <br />
          <Typography.Text>
            <strong>Advisor Practice: </strong>
            {billTo.advisorPractice}
          </Typography.Text>
        </Col>
        <Col>
          <img width="200px" src={PlanlogicLogo} alt="Planlogic" />
        </Col>
      </Row>
      <div className="pricing-ui-container">
        <Row>
          <Col span={14} className="font-weight-bold py-3">
            {header.title}
          </Col>
          <Col span={5} className="font-weight-bold py-3 text-center">
            Quantity
          </Col>
          <Col span={5} className="font-weight-bold py-3 text-center">
            Amount
          </Col>
        </Row>
        {header.serviceOffering.visible && (
          <Row className="header">
            <Col span={14} className="pt-2">
              <Typography.Title level={5}>
                {header.serviceOffering.description}
              </Typography.Title>
            </Col>
            <Col span={5}></Col>
            <Col span={5}></Col>

            <Col span={14} className="pb-2">
              <Select
                options={Object.keys(serviceOfferingOptions).map(key => ({
                  value: key,
                  label: serviceOfferingOptions[key],
                }))}
                defaultValue={header.serviceOffering.line_items[0].key}
                dropdownStyle={{ zIndex: 9999999999, minWidth: "160px" }}
                bordered={false}
                onChange={onServiceOfferingChange}
                disabled={disabled}
              />
            </Col>
            <Col span={5}></Col>
            <Col span={5} className="amount">
              <span>$</span>
              <span>
                {Number(header.serviceOffering.line_items[0].unit_cost).toFixed(
                  2
                )}
              </span>
            </Col>
          </Row>
        )}
        {sections.map((section, i) => {
          if (!section.visible) {
            return null;
          }
          return (
            <Row key={i} className="section">
              <Col span={14} className="pt-2">
                <Typography.Title level={5}>
                  {section.description}
                </Typography.Title>
              </Col>
              <Col span={5}></Col>
              <Col span={5}></Col>
              {section.line_items.map((item, j) => {
                if (!item.visible) {
                  return null;
                }

                return (
                  <>
                    <Col span={14} className="py-2">
                      <Typography.Text level={5}>
                        {item.description}
                      </Typography.Text>
                    </Col>
                    <Col span={5} className="no-of py-2">
                      <InputNumber
                        readOnly={disabled}
                        value={item.quantity}
                        min={0}
                        size="small"
                        controls={false}
                        bordered={false}
                        onChange={value => {
                          onSectionItemQuantityChange(value, item.id);
                        }}
                      />
                    </Col>
                    <Col span={5} className="amount py-2">
                      <span>$</span>
                      <span>
                        {item.quantity
                          ? Number(item.quantity * item.unit_cost).toFixed(2)
                          : "-"}
                      </span>
                    </Col>
                  </>
                );
              })}
            </Row>
          );
        })}
      </div>

      <div className="pricing-ui-container">
        <Row className="total">
          <Col span={19} className="font-weight-bold py-2">
            Total Price (EX.GST)
          </Col>
          <Col span={5} className="amount py-2">
            <span>$</span>
            <span>{totalPriceExGst.toFixed(2) || "-"}</span>
          </Col>
        </Row>
      </div>

      {modifiersVisible && (
        <div className="pricing-ui-container">
          {modifiers.map((modifier, i) => {
            if (!modifier.visible) {
              return null;
            }

            const types = getModifierTypes(modifier);

            return (
              <Row key={i} className="modifier">
                <Col span={14} className="py-2">
                  {modifier.description}
                </Col>
                <Col span={5} className="py-2">
                  <InputNumber
                    readOnly={disabled}
                    value={modifier.quantity}
                    min={0}
                    size="small"
                    formatter={
                      types[1] === "dollar"
                        ? value =>
                            `$${value}`.replace(
                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                        : value => `${value}%`
                    }
                    parser={
                      types[1] === "dollar"
                        ? value => value.replace(/\$\s?|(,*)/g, "")
                        : value => value.replace("%", "")
                    }
                    controls={false}
                    bordered={false}
                    onChange={value => {
                      onModifierItemQuantityChange(value, modifier.id);
                    }}
                  />
                </Col>
                <Col
                  span={5}
                  className={`amount py-2 ${modifier.quantity ? types[0] : ""}`}
                >
                  <span>$</span>
                  <span>
                    {types[1] === "dollar"
                      ? modifier.quantity
                        ? modifier.quantity.toFixed(2)
                        : "-"
                      : totalPriceExGst * (modifier.quantity / 100) === 0
                      ? "-"
                      : (totalPriceExGst * (modifier.quantity / 100)).toFixed(
                          2
                        )}
                  </span>
                </Col>
              </Row>
            );
          })}
        </div>
      )}

      {modifiersVisible && (
        <div className="pricing-ui-container">
          <Row className="total">
            <Col span={19} className="font-weight-bold py-2">
              Final Price (EX.GST)
            </Col>
            <Col span={5} className="amount py-2">
              <span>$</span>
              <span>{finalPriceExGst.toFixed(2) || "-"}</span>
            </Col>
          </Row>
        </div>
      )}

      <div className="pricing-ui-container">
        <Row className="total">
          <Col span={19} className="py-2">
            GST @ 10%
          </Col>
          <Col span={5} className="amount py-2">
            <span>$</span>
            <span>{gst.toFixed(2) || "-"}</span>
          </Col>
        </Row>

        <Row className="total">
          <Col span={19} className="font-weight-bold py-2">
            Amount Due
          </Col>
          <Col span={5} className="amount py-2">
            <span>$</span>
            <span>{finalPrice.toFixed(2) || "-"}</span>
          </Col>
        </Row>
      </div>
      <ReactQuill
        readOnly={disabled}
        theme="snow"
        className="email-input"
        placeholder=""
        bounds={`[data-text-editor="form-editor"]`}
        value={otherPricingInfomation}
        onChange={value => setOtherPricingInfo(value)}
        modules={{
          toolbar: disabled
            ? false
            : [
                [
                  "bold",
                  "italic",
                  "underline",
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                  { align: "" },
                  { align: "center" },
                  { align: "right" },
                ],
                [{ header: "1" }, { header: "2" }],
                [{ size: [] }],
              ],
        }}
      />
      {showSubmit && (
        <Row justify="end" style={{ marginTop: 16 }}>
          <Col>
            <Popconfirm
              title="Are you sure to finalize the pricing?"
              onConfirm={onSubmit}
              placement="topRight"
              zIndex={9999999999}
            >
              <Button
                type="primary"
                disabled={disabled || finalPrice.toFixed(2) < 0}
              >
                Confirm
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PricingUI;
