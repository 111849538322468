import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="24px"
      height="28px"
      viewBox="0 0 24 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Upload Icon</title>
      <g
        id="Upload-tab"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Post-upload-UI"
          transform="translate(-549.000000, -367.000000)"
          fill="#4589FF"
        >
          <g id="Group" transform="translate(434.000000, 365.000000)">
            <g
              transform="translate(111.000000, 0.000000)"
              id="Organization-/-File-/-upload-/-24"
            >
              <path
                d="M6,17 L7.41,18.41 L15,10.83 L15,30 L17,30 L17,10.83 L24.59,18.41 L26,17 L16,7 L6,17 Z M6,8 L6,4 L26,4 L26,8 L28,8 L28,4 C28,2.8954305 27.1045695,2 26,2 L6,2 C4.8954305,2 4,2.8954305 4,4 L4,8 L6,8 Z"
                id="Fill"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UploadIcon;
