import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Comment,
  Avatar,
  Row,
  message,
  Modal,
  Dropdown,
  Menu,
  Button,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  LoadingOutlined,
  EyeOutlined,
  DownOutlined,
  DeleteOutlined,
  WarningOutlined,
  FileOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../Auth";
import moment from "moment";
import { getFileAttachment } from "../../api/currentPosition/comments";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { checkIsImage, getFileIcon, getFileType } from "./utils";

import { useGetStatus } from "../../redux/hooks/request";
import {
  COMMENT_MAX_WIDTH,
  COMMENT_PREVIEW_MAX_LENGTH,
  REQUEST_STATUS,
} from "../../utils/constants";
import ReplyIcon from "../../components/Icons/ReplyIcon";
import { useGetModalValues } from "../../redux/hooks/modals";
import { downloadFile } from "../../api/paraplanner/soaGeneration";

import "./index.css";

const ReplyToPreview = ({ replyToId }) => {
  const { comments } = useGetModalValues("commentsDrawer");

  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const { stakeholders } = useGetModalValues("commentsDrawer");

  if (!replyToId) {
    return null;
  }

  const comment = comments?.find(c => c.commentId === replyToId);

  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const alignRight = shouldAlignRight({
    stakeholders,
    ownerId: comment?.owner?.id,
    userIsOwner: isOwner,
    userIsParaplanner: isParaplanner,
  });

  const showName = isParaplanner || alignRight;

  const name = showName ? comment?.owner?.name : "PL Team";

  const onPreviewClick = () => {
    const comment = document.getElementById(`comment-${replyToId}`);
    if (comment) {
      comment.scrollIntoView(false);
      comment.classList.add("pulse-animate");
      setTimeout(() => {
        comment.classList.remove("pulse-animate");
      }, 2000);
    }
  };
  if (!comment) {
    return null;
  }

  return (
    <div className="reply-to-preview" onClick={onPreviewClick}>
      <span className="author">{name}</span>
      <span className="content">
        {comment.attachments.length === 0 ? (
          comment.content.length > COMMENT_PREVIEW_MAX_LENGTH ? (
            `${comment.content.slice(0, COMMENT_PREVIEW_MAX_LENGTH)}...`
          ) : (
            comment.content
          )
        ) : (
          <>
            <FileOutlined /> {comment.attachments.length} Attachment
            {comment.attachments.length === 1 ? "" : "s"}
          </>
        )}
      </span>
    </div>
  );
};

const FileAttachment = ({
  comment,
  attachment,
  handlePreviewFile,
  handleDeleteFile,
  handleDeleteComment,
}) => {
  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const onDeleteConfirm = () => {
    if (comment.attachments.length === 1 && !comment.content) {
      handleDeleteComment(comment.commentId);
    } else {
      handleDeleteFile({
        commentId: comment.commentId,
        attachementId: attachment.id,
      });
    }
  };

  const isImage = checkIsImage(attachment.extension);

  const [url, setUrl] = useState(null);

  const fetchUrl = async () => {
    const url = await handlePreviewFile({
      commentId: comment.commentId,
      attachementId: attachment.id,
      fileTitle: attachment.fileName || attachment.name,
      extension: attachment.extension,
      showModal: false,
    });

    setUrl(url);
  };

  useEffect(() => {
    if (isImage) {
      fetchUrl();
    }
  }, [isImage]);

  const handleDownloadFile = async () => {
    let url = await handlePreviewFile({
      commentId: comment.commentId,
      attachementId: attachment.id,
      fileTitle: attachment.fileName || attachment.name,
      extension: attachment.extension,
      showModal: false,
    });

    const fileName = attachment.fileName;

    downloadFile(url, fileName, true);
  };

  return (    
    <div className={`file-attachment ${isImage ? "is-image" : ""}`}>
      {comment.loading ? (
        <>
          <LoadingOutlined />
          <p>Uploading...</p>
        </>
      ) : (
        <>
          <div className={`file-preview`}>
            <EyeOutlined
              title="Preview file"
              onClick={() => {
                handlePreviewFile({
                  commentId: comment.commentId,
                  attachementId: attachment.id,
                  fileTitle: attachment.fileName || attachment.name,
                  extension: attachment.extension,
                });
              }}
            />
            {isOwner && isParaplanner && (
              <Popconfirm
                overlayStyle={{ zIndex: 999999999999999 }}
                placement="bottomRight"
                title="Are you sure you want to delete this file?"
                onConfirm={onDeleteConfirm}
                arrowPointAtCenter
              >
                <DeleteOutlined title="Delete file" />
              </Popconfirm>
            )}
            <DownloadOutlined
              title="Download file"
              onClick={handleDownloadFile}
            />
          </div>
          {isImage ? (
            url ? (
              <>
              <img
                className="preview-image"
                src={url}
                alt={attachment.fileName || attachment.name}
              />
              </>
            ) : (
              <LoadingOutlined />
            )
          ) : (
            <>
              {getFileIcon(attachment.extension)}
              <p>{attachment.fileName || attachment.name}</p>
            </>
          )}
        </>
      )}
    </div> 
  );
};

export const shouldAlignRight = ({
  stakeholders,
  ownerId,
  userIsOwner,
  userIsParaplanner,
}) => {
  // In case stake holders havent loaded yet
  if (stakeholders.length === 0) {
    return userIsOwner;
  }

  // For current logged in users
  if (userIsOwner) {
    return true;
  }

  // For commenters when logged in user is paraplanner
  if (userIsParaplanner) {
    return (
      stakeholders.find(stakeholder => stakeholder.id === ownerId)?.type ===
      "paraplanner"
    );
  }

  // For commenters when logged in user is not paraplanner
  return (
    stakeholders.find(stakeholder => stakeholder.id === ownerId)?.type !==
    "paraplanner"
  );
};

const CommentsListItem = ({
  isBeingEdited,
  comment,
  handlePreviewFile,
  handleDeleteComment,
  handleDeleteFile,
  setEditCommentId,
  setReplyToComment,
  handleRequestConfirmation,
  handleRequestEditApproval,
  replyToId,
}) => {
  const {
    parsedToken: {
      sub,
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const [replyHintVisible, setReplyHintVisible] = useState(false);

  const { stakeholders } = useGetModalValues("commentsDrawer");

  const status = useGetStatus();
  const isParaplanner = roles && roles.includes("paraplanner");

  const isOwner = sub === comment?.owner?.id;

  const requestEditedNotOwner =
    !isOwner &&
    comment?.requestEdited &&
    typeof comment.editApproved !== "boolean";

  const requestEdited =
    comment?.requestEdited && typeof comment.editApproved !== "boolean";

  const requestEditApproved = comment.editApproved;

  const alignRight = shouldAlignRight({
    stakeholders,
    ownerId: comment?.owner?.id,
    userIsOwner: isOwner,
    userIsParaplanner: isParaplanner,
  });

  const showName = isParaplanner || alignRight;

  const name = showName ? comment?.owner?.name : "PL Team";

  const [ownerFirstName, ownerLastName] = name.split(" ");

  const initials = showName
    ? `${ownerFirstName ? ownerFirstName[0] : ""}` +
      `${ownerLastName ? ownerLastName[0] : ""}`
    : "PL";

  const replyButton = (
    <Tooltip
      visible={replyHintVisible}
      title="Click here to reply"
      overlayStyle={{ zIndex: 99999999999 }}
    >
      <Button
        className="reply-to-button"
        shape="circle"
        size="small"
        icon={<ReplyIcon />}
        onClick={() => {
          setReplyToComment({ id: comment.commentId, type: "reply" });
        }}
      />
    </Tooltip>
  );

  const data = {
    id: comment.commentId,
    author: <>{name}</>,
    avatar: comment?.owner?.avatarUrl || (
      <Avatar
        alt={name}
        style={{
          backgroundColor: alignRight ? "#7765CF" : "#878D96",
        }}
      >
        {initials}
      </Avatar>
    ),
    content: (
      <>
        <ReplyToPreview replyToId={replyToId} />
        {comment.attachments.length === 0 ? (
          <p>{comment.content}</p>
        ) : (
          <>
            <div
              className="file-attachments"
              style={{
                direction: alignRight ? "rtl" : "ltr",
              }}
            >
              <div className="file-attachment-with-text-container"> 
                 { comment.content ? 
                    <div className="preview-comment">
                      <p>{comment.content}&lrm;</p>
                   </div> 
                  : ""
                } 
                <div className="file-attachment-container">
              {comment.attachments.map(attachment => {
                return (
                  <FileAttachment
                    key={attachment.id}
                    comment={comment}
                    attachment={attachment}
                    handlePreviewFile={handlePreviewFile}
                    handleDeleteFile={handleDeleteFile}
                    handleDeleteComment={handleDeleteComment}
                  />
                );
              })}
              </div>
              </div>
            </div>
            {comment.loading && (
              <span className="file-uploading">
                <span className="file-uploading__element" />
              </span>
            )}
          </>
        )}
      </>
    ),
    attachments: comment.attachments,
    datetime: (
      <>
        {alignRight && replyButton}
        {typeof requestEditApproved === "boolean" &&
          (requestEditApproved ? (
            <span className="request-edit-approved">Approved</span>
          ) : (
            <span className="request-edit-rejected">Rejected</span>
          ))}
        <span className="ant-comment-content-author-name">{name}</span>
        {moment.parseZone(comment.createdOn).fromNow(true)} ago{" "}
        {requestEdited && (
          <span className="request-confirmation">Confirmation Requested</span>
        )}
        {!alignRight && replyButton}
      </>
    ),
  };

  const showDropdown =
    isParaplanner &&
    isOwner &&
    [REQUEST_STATUS.inReview_reviewing, REQUEST_STATUS.processing].includes(
      status
    ) &&
    !comment.loading;

  // const showEdit = comment.attachments.length === 0;
  const showEdit = comment?.content?.length > 0;

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const onDeleteMenuClick = () => {
    setIsDeleteConfirmOpen(true);
  };

  const onDeleteConfirm = () => {
    handleDeleteComment(data.id);
    setIsDeleteConfirmOpen(false);
  };

  const onDeleteCancel = () => {
    setIsDeleteConfirmOpen(false);
  };

  const onEditMenuClick = () => {
    setEditCommentId(comment.commentId);
  };

  const onRequestConfirmationMenuClick = () => {
    handleRequestConfirmation({ commentId: comment.commentId });
  };

  const onRequestEditApprove = () => {
    handleRequestEditApproval({
      commentId: comment.commentId,
      editApproved: true,
    });
    setReplyToComment({ id: comment.commentId, type: "approve" });
  };

  const onRequestEditReject = () => {
    handleRequestEditApproval({
      commentId: comment.commentId,
      editApproved: false,
    });
    setReplyToComment({ id: comment.commentId, type: "reject" });
  };

  const actions = (
    <Menu subMenuCloseDelay={0}>
      <Menu.Item onClick={onRequestConfirmationMenuClick}>
        Request Confirmation
      </Menu.Item>
      {showEdit && <Menu.Item onClick={onEditMenuClick}>Edit</Menu.Item>}
      <Menu.Item onClick={onDeleteMenuClick}>Delete</Menu.Item>
    </Menu>
  );

  const actionDropdown = (
    <Dropdown
      trigger={["click"]}
      overlay={actions}
      overlayClassName="comment-action-dropdown"
      className="comment-action"
    >
      <Button shape="circle" size="small" icon={<DownOutlined />} />
    </Dropdown>
  );

  return (
    <>
      <Row
        onMouseEnter={() => {
          setReplyHintVisible(true);
        }}
        onMouseLeave={() => {
          setReplyHintVisible(false);
        }}
        id={`comment-${data.id}`}
        className={`comment-list-item ${
          alignRight ? "align-comment-right" : ""
        } ${isBeingEdited ? "comment-being-edited" : ""} ${
          requestEditedNotOwner ? "request-edited-comment" : ""
        }`}
      >
        <Popconfirm
          visible={isDeleteConfirmOpen}
          overlayStyle={{ zIndex: 999999999999999 }}
          placement="bottomRight"
          title="Are you sure you want to delete this comment?"
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
        >
          <Comment
            style={{ maxWidth: COMMENT_MAX_WIDTH }}
            className="comment"
            content={data.content}
            avatar={data.avatar}
            datetime={data.datetime}
          />
        </Popconfirm>

        {showDropdown ? actionDropdown : null}
      </Row>
      {requestEditedNotOwner && (
        <Row className="request-edit-approval">
          <div className="title">
            <WarningOutlined /> <strong>Action required</strong>
          </div>
          <p className="text">Please mark your option on the above change</p>
          <div className="actions">
            <Popconfirm
              overlayStyle={{ zIndex: 999999999999999 }}
              placement="bottom"
              title="Are you sure you want to reject this edit?"
              onConfirm={onRequestEditReject}
            >
              <Button shape="round" type="default">
                Reject
              </Button>
            </Popconfirm>

            <Popconfirm
              overlayStyle={{ zIndex: 999999999999999 }}
              placement="bottom"
              title="Are you sure you want to approve this edit?"
              onConfirm={onRequestEditApprove}
            >
              <Button shape="round" type="primary">
                Approve
              </Button>
            </Popconfirm>
          </div>
        </Row>
      )}
    </>
  );
};

const CommentsList = React.memo(
  ({
    comments,
    isDrawerVisible,
    handleDeleteComment,
    handleDeleteFile,
    setEditCommentId,
    editCommentId,
    setReplyToComment,
    handleRequestConfirmation,
    handleRequestEditApproval,
  }) => {
    const list = useRef(null);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewFileUrl, setPreviewFileUrl] = useState(null);
    const [previewFileType, setPreviewFileType] = useState(null);

    const handlePreviewFile = async ({
      commentId,
      attachementId,
      fileTitle,
      extension,
      showModal = true,
    }) => {
      try {
        const url = await getFileAttachment({
          commentId: commentId,
          attachementId: attachementId,
        });

        if (showModal) {
          setPreviewVisible(true);
          setPreviewTitle(fileTitle);
          setPreviewFileUrl(url);
          setPreviewFileType(getFileType(extension));
        }

        return url;
      } catch (error) {
        message.error(`Couldn't preview ${fileTitle}`);
      }
    };

    const handleModalClose = () => {
      setPreviewVisible(false);
      setPreviewTitle("");
      setPreviewFileUrl(null);
      setPreviewFileType(null);
    };

    useEffect(() => {
      if (isDrawerVisible) {
        const requestEditedComments = document.querySelectorAll(
          ".request-edited-comment:not(.scrolled)"
        );

        if (requestEditedComments && requestEditedComments.length > 0) {
          requestEditedComments.forEach(el => {
            el.classList.add("scrolled");
          });
          requestEditedComments[0].scrollIntoView(true);
          return;
        }

        if (list) {
          list.current.scrollIntoView(false);
        }
      }
    }, [comments, isDrawerVisible]);

    return (
      <div
        ref={list}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        {comments.map(item => {
          return (
            <CommentsListItem
              key={item.commentId}
              isBeingEdited={editCommentId === item.commentId}
              replyToId={item.replyToId}
              comment={item}
              handlePreviewFile={handlePreviewFile}
              handleDeleteComment={handleDeleteComment}
              handleDeleteFile={handleDeleteFile}
              setEditCommentId={setEditCommentId}
              setReplyToComment={setReplyToComment}
              handleRequestConfirmation={handleRequestConfirmation}
              handleRequestEditApproval={handleRequestEditApproval}
            />
          );
        })}
        <Modal
          className="file-preview-modal"
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleModalClose}
          zIndex={10000000000}
          bodyStyle={{
            minHeight: 450,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          width="100%"
          style={{ maxWidth: 800 }}
          centered
        >
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: previewFileUrl,
                fileType: previewFileType,
              },
            ]}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            style={{ height: "100%", width: "100%", minHeight: 450 }}
          />
        </Modal>
      </div>
    );
  }
);

export default CommentsList;
