import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Drawer, message } from "antd";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import CommentsList from "./CommentsList";
import AddComment from "./AddComment";
import { useDropzone } from "react-dropzone";
import {
  useAcknowledgeComments,
  useAddComment,
  useGetComments,
  useDeleteComment,
  useDeleteFileAttachment,
  useEditComment,
  useRequestConfirmation,
  useRequestEditApproval,
} from "../../api/currentPosition/comments";
import { useGetModalValues, useSetModalValues } from "../../redux/hooks/modals";
import UploadIcon from "../../components/Icons/UploadIcon";

import "./index.css";
import CommentsUploadIcon from "../../components/Icons/CommentsUploadIcon";
import { tabEntities } from "../../components/WithComments/constants";
import MegaphoneIcon from "../../components/Icons/MegaphoneIcon";
import { useGetCommentsAllowed, useGetStatus } from "../../redux/hooks/request";
import { COMMENT_DRAWER_WIDTH, REQUEST_STATUS } from "../../utils/constants";
import { AuthContext } from "../../Auth";
import { ACCEPTED_FILE_FORMATS } from "./utils";

const GeneralMessageInfo = () => {
  return (
    <div className="general-message-info">
      <MegaphoneIcon />
      <p>You can use this message thread to communicate general queries</p>
    </div>
  );
};

const CommentsDrawer = () => {
  const setModalValues = useSetModalValues();
  const {
    open,
    entityType,
    entityId,
    key,
    title,
    comments,
    editCommentId,
    replyToCommentId,
    replyToCommentType,
  } = useGetModalValues("commentsDrawer");

  const commentsAllowed = useGetCommentsAllowed();
  const readOnly = !commentsAllowed;

  const onDrawerClose = () => {
    setModalValues("commentsDrawer", {
      open: false,
      entityType: "",
      entityId: "",
      key: "__root",
      title: "",
      comments: [],
      editCommentId: "",
      replyToCommentId: "",
      replyToCommentType: "",
    });
    setFilesPreview([]);
  };

  const setEditCommentId = id => {
    setModalValues("commentsDrawer", {
      editCommentId: id,
    });
  };

  const setReplyToComment = ({ id, type }) => {
    setModalValues("commentsDrawer", {
      replyToCommentId: id,
      replyToCommentType: type,
    });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const {
    data: commentsData,
    isFetching,
    refetch: fetchComments,
  } = useGetComments(entityType, entityId, key);

  const requestState = useGetStatus();

  const [addNewComment, { isLoading: isAddCommentPending }] = useAddComment({
    fetchComments: fetchComments,
  });

  const [
    deleteComment,
    { isLoading: isDeletingCommentPending },
  ] = useDeleteComment({
    fetchComments: fetchComments,
  });

  const [
    deleteFileAttachment,
    { isLoading: isDeletingFilePending },
  ] = useDeleteFileAttachment({
    fetchComments: fetchComments,
  });

  const [editComment, { isLoading: isEditCommentPending }] = useEditComment({
    fetchComments: fetchComments,
  });

  const [
    requestConfirmation,
    { isLoading: isRequestConfirmationPending },
  ] = useRequestConfirmation({
    fetchComments: fetchComments,
  });

  const [
    requestEditApproval,
    { isLoading: isRequestEditApprovalPending },
  ] = useRequestEditApproval({
    fetchComments: fetchComments,
  });

  const status = useGetStatus();

  const {
    parsedToken: {
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const disableUnreadComments =
    roles?.includes("advisor") && status === REQUEST_STATUS.inReview_reviewing;

  const [acknowledgeComments] = useAcknowledgeComments();


  const handleAddComment = comment => {
    //Sending only files
    if (filesPreview && (comment == ("" || null))) {
      addNewComment({
        entityType,
        entityId,
        key,
        replyToId: replyToCommentId,
        requestState,
        files: filesPreview
      });
    }

    //Sending files along with the comment
    if ((filesPreview?.length !== 0) && (comment !== ("" || null))) {
      addNewComment({
        entityType,
        entityId,
        key,
        content: comment,
        replyToId: replyToCommentId,
        requestState,
        files: filesPreview
      });
    }


    //Sending only comment
    if (filesPreview.length == 0 && comment)
      // if(comment !== ("" || null))
      addNewComment({
        entityType,
        entityId,
        key,
        content: comment,
        replyToId: replyToCommentId,
        requestState,
      });

    setReplyToComment({ id: "", type: "" });
    setFilesPreview([]);
  };

  const handleAddFiles = fileList => {
    const acceptedFiles = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (ACCEPTED_FILE_FORMATS.includes(file.type)) {
        acceptedFiles.push(file);
      } else {
        message.error(
          `Could not upload ${file.name
          } - File must be one of type: ${ACCEPTED_FILE_FORMATS.join(", ")}`
        );
      }
    }

    if (acceptedFiles.length > 0) {
      addNewComment({
        entityType,
        entityId,
        key,
        content: "",
        files: acceptedFiles,
      });
    }
  };

  const handleDeleteComment = commentId => {
    deleteComment({ entityType, entityId, key, commentId });
  };

  const handleDeleteFile = ({ commentId, attachementId }) => {
    deleteFileAttachment({
      entityType,
      entityId,
      key,
      commentId,
      attachementId,
    });
  };

  const handleEditComment = ({ commentId, content }) => {
    editComment({
      entityType,
      entityId,
      key,
      commentId,
      content,
    });
    setEditCommentId(null);
  };

  const handleRequestConfirmation = ({ commentId }) => {
    requestConfirmation({
      entityType,
      entityId,
      key,
      commentId,
    });
  };

  const handleRequestEditApproval = ({ commentId, editApproved }) => {
    requestEditApproval({
      entityType,
      entityId,
      key,
      commentId,
      editApproved,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (commentsData) {
      setModalValues("commentsDrawer", {
        comments: disableUnreadComments
          ? commentsData.attributes.comments.filter(comment => comment.read)
          : commentsData.attributes.comments,
      });
    }
  }, [commentsData]);

  useEffect(() => {
    if (entityType && entityId && key) {
      fetchComments();
      if (!disableUnreadComments) {
        acknowledgeComments({ entityType, entityId, key });
      }
    }
  }, [entityType, entityId, key, disableUnreadComments]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (editCommentId && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editCommentId]);

  //States declaring
  const [filesPreview, setFilesPreview] = useState([]);

  const handlePreviewRemove = (index) => {
    const updatedFilesPreview = [...filesPreview];
    updatedFilesPreview.splice(index, 1);
    setFilesPreview(updatedFilesPreview);
  }

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setFilesPreview([...filesPreview, ...acceptedFiles]);
      }
    },
    [addNewComment, entityId, entityType, key, filesPreview]
  );

  const onDropRejected = useCallback(fileRejections => {
    fileRejections.forEach(rejection => {
      message.error(
        `Could not upload ${rejection.file.name} - ${rejection.errors[0].message}`
      );
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
    isDragActive,
  } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: ACCEPTED_FILE_FORMATS,
    disabled: readOnly,
  });

  const showInfo =
    entityId === tabEntities.general.entityId &&
    commentsData &&
    comments?.length === 0;

  return (
    <>
      <Drawer
        title={title}
        width={COMMENT_DRAWER_WIDTH}
        placement="right"
        onClose={onDrawerClose}
        closable={false}
        visible={open}
        style={{
          zIndex: 10000000000,
        }}
        footer={null}
        className="comments-drawer"
        forceRender
      >
        <div
          {...getRootProps({
            className: `upload-container ${isDragActive ? "drag-active" : ""}`,
          })}
        >
          <div className="comments-list">
            {showInfo ? (
              <GeneralMessageInfo />
            ) : (
              <CommentsList
                comments={comments}
                isDrawerVisible={open}
                handleDeleteComment={handleDeleteComment}
                isDeletingCommentPending={isDeletingCommentPending}
                handleDeleteFile={handleDeleteFile}
                isDeletingFilePending={isDeletingFilePending}
                setEditCommentId={setEditCommentId}
                editCommentId={editCommentId}
                setReplyToComment={setReplyToComment}
                handleRequestConfirmation={handleRequestConfirmation}
                handleRequestEditApproval={handleRequestEditApproval}
              />
            )}
            {isFetching ? (
              <LoadingOutlined style={{ margin: "0 20px" }} />
            ) : null}
          </div>
          <div className="bottom-section">
            {/* Added editCommentId to check whether it's in edit mode */}
            {!replyToCommentId && (
              <Button
                className="upload-button"
                onClick={openFileDialog}
                icon={<UploadIcon />}
                disabled={readOnly || disableUnreadComments || editCommentId}
              >
                Click or Drag file to upload
              </Button>
            )}

            {/* Add file preview in the comment  */}
            <div className="preview">
              {
                filesPreview?.length > 0 &&
                filesPreview?.map((file, index) =>
                  file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/bmp" ?
                    <div className="preview-img-container">
                      <img crossOrigin="anonymous" loading="lazy" className="preview-img" src={URL.createObjectURL(file)} />
                      <CloseCircleOutlined
                        className="preview-img-cancel"
                        key={file}
                        onClick={() => handlePreviewRemove(index)} />
                    </div>
                    :
                    <div className="preview-file-container">
                      <CloseCircleOutlined
                        className="preview-file-cancel"
                        onClick={() => handlePreviewRemove(index)} />
                      <div>{file.name}</div>
                    </div>
                )
              }
            </div>

            <div className="add-comment">
              <input
                {...getInputProps({
                  className: "upload-input",
                })}
              />

              <AddComment
                readOnly={readOnly || disableUnreadComments}
                handleAddComment={handleAddComment}
                handleEditComment={handleEditComment}
                isEditCommentPending={isEditCommentPending}
                isAddCommentPending={isAddCommentPending}
                inputRef={inputRef}
                editCommentId={editCommentId}
                replyToCommentId={replyToCommentId}
                replyToCommentType={replyToCommentType}
                setReplyToComment={setReplyToComment}
                handleAddFiles={handleAddFiles}
                filesPreview={filesPreview}
                setFilesPreview={setFilesPreview}
              />
              <div
                className={`drop-message ${isDragActive ? "drag-active" : ""}`}
              >
                <CommentsUploadIcon />
                <p>Drop files to instantly upload them</p>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CommentsDrawer;
