import ApiUtils from "./../../utils/apiUtils";
import { useMutation, useQuery } from "react-query";
import { message, notification } from "antd";
import { uniqueId } from "lodash";
import {
  decrementCommentCount,
  incrementCommentCount,
  markAsRead,
  updateComments,
} from "../../containers/CommentsDrawer/utils";
import { useSetModalValues, useGetModalValues } from "../../redux/hooks/modals";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../../Auth";
import { getSoaId } from "../../utils/common";
import { REQUEST_STATUS } from "../../utils/constants";
import { useGetStatus } from "../../redux/hooks/request";

const getComments = async ({ queryKey }) => {
  const { entityType, entityId, key, soaId } = queryKey[1];
  const config = {
    headers: {},
  };

  return ApiUtils.HTTPS.get(
    `/comment/${soaId}/comment/${entityType}/${entityId}/${key}`,
    config
  ).then(res => res.data.data);
};

const useGetComments = (entityType, entityId, key) => {
  const soaId = getSoaId();
  const { data, error, isFetching, refetch } = useQuery(
    ["/comment", { entityType, entityId, key, soaId }],
    getComments,
    {
      onError: () => {
        message.error("Something went wrong fetching the comments data..!!");
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      enabled: false,
    }
  );

  return { data, error, isFetching, refetch };
};

const getAllComments = async ({ queryKey }) => {
  const { soaId } = queryKey[1];

  const config = {
    headers: {},
  };

  return ApiUtils.HTTPS.get(`/comment/${soaId}/comment`, config).then(
    res => res.data.data
  );
};

const getStakeholders = async ({ queryKey }) => {
  const { dealerGrp, userIds = [] } = queryKey[1];

  let promises = userIds.map(userId =>
    ApiUtils.HTTPS.get(`/realm/user/${dealerGrp}/${userId}`)
  );

  return Promise.all(promises);
};

const useGetStakeholders = advisorIds => {
  const setModalValues = useSetModalValues();

  const {
    parsedToken: { planpod_dealer_group },
  } = useContext(AuthContext);

  const { data, isLoading } = useQuery(
    ["/stakeholders", { dealerGrp: planpod_dealer_group, userIds: advisorIds }],
    getStakeholders,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: Boolean(advisorIds),
      onError: () => {},
      onSuccess: data => {
        setModalValues("commentsDrawer", {
          stakeholders: data.map(res => res.data.data),
        });
      },
    }
  );

  return { data, isLoading };
};

const useGetAllComments = enabled => {
  const setModalValues = useSetModalValues();

  const status = useGetStatus();

  const {
    parsedToken: {
      realm_access: { roles },
    },
  } = useContext(AuthContext);

  const disableUnreadComments =
    roles?.includes("advisor") && status === REQUEST_STATUS.inReview_reviewing;

  const soaId = getSoaId();

  const { data, error, isFetching, refetch } = useQuery(
    ["/comment", { soaId }],
    getAllComments,
    {
      onError: () => {
        message.error("Something went wrong fetching the comments data..!!");
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      enabled,
    }
  );

  useGetStakeholders(data?.attributes?.stakeholders);

  useEffect(() => {
    if (data) {
      if (!disableUnreadComments) {
        setModalValues("commentsDrawer", { summary: data });
      } else {
        setModalValues("commentsDrawer", {
          summary: {
            ...data,
            attributes: {
              ...data?.attributes,
              totalComments:
                data?.attributes.totalComments -
                data?.attributes.unreadComments,
              unreadComments: 0,
              totalRequestEditedComments: 0,
              entities: data?.attributes?.entities?.map(entity => ({
                ...entity,
                totalComments: entity.totalComments - entity.unreadComments,
                unreadComments: 0,
                totalRequestEditedComments: 0,
                threads: entity?.threads?.map(thread => ({
                  ...thread,
                  totalComments: thread.totalComments - thread.unreadComments,
                  unreadComments: 0,
                  totalRequestEditedComments: 0,
                  comments: thread?.comments?.filter(comment => comment.read),
                })),
              })),
            },
          },
        });
      }
    }
  }, [data, disableUnreadComments]);

  return refetch;
};

const addComment = async ({
  entityType,
  entityId,
  key,
  content,
  files,
  replyToId,
  requestState,
}) => {
  var data = new FormData();
  data.append(
    "request",
    JSON.stringify({
      data: {
        type: "comment",
        attributes: {
          entityType,
          entityId,
          key,
          content,
          replyToId,
          requestState,
        },
      },
    })
  );
  if (files) {
    files.forEach(file => {
      data.append("attachments", file);
    });
  }

  const adviceId = getSoaId();

  return ApiUtils.HTTPS({
    url: `/comment/${adviceId}/comment/`,
    method: "post",
    data: data,
  });
};

const useAddComment = ({ fetchComments }) => {
  const {
    parsedToken: { name, sub },
  } = useContext(AuthContext);

  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );

  const { mutateAsync, isLoading } = useMutation(addComment, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, content, files, replyToId }) => {
      const comments = [
        ...prevComments,
        {
          commentId: uniqueId("new-"),
          content,
          attachments: files || [],
          loading: true,
          createdBy: sub,
          owner: {
            id: sub,
            name,
          },
          replyToId,
        },
      ];

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...incrementCommentCount(
            summary,
            entityType,
            entityId,
            key,
            comments
          ),
        },
      });
    },
    onError: () => {
      message.error("Comment could not be added..!!");
    },
  });

  return [mutateAsync, { isLoading }];
};

const acknowledgeComments = async ({ entityType, entityId, key }) => {
  const adviceId = getSoaId();

  return ApiUtils.HTTPS.put(
    `/comment/${adviceId}/acknowledge/${entityType}/${entityId}/${key}`
  );
};

const useAcknowledgeComments = () => {
  const setModalValues = useSetModalValues();
  const { summary } = useGetModalValues("commentsDrawer");

  const { mutateAsync, data, error, isFetching } = useMutation(
    acknowledgeComments,
    {
      onError: () => {
        message.error("Comments not acknowledged..!!");
      },
      onMutate: ({ entityType, entityId, key }) => {
        setModalValues("commentsDrawer", {
          summary: {
            ...markAsRead(summary, entityType, entityId, key),
          },
        });
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  return [mutateAsync, { data, error, isFetching }];
};

const getCommentsSummary = async ({ queryKey }) => {
  const { requestData } = queryKey[1];
  const adviceIds = requestData.data.requests.map(req => req.soa.soaId);

  return ApiUtils.HTTPS.post(`comment/summary`, {
    data: {
      type: "adviceList",
      attributes: {
        adviceIds: adviceIds,
      },
    },
  });
};

const useGetCommentsSummary = requestData => {
  const { data, error, isFetching } = useQuery(
    ["comment/summary", { requestData }],
    getCommentsSummary,
    {
      onError: () => {
        message.error("Could not get advice comments..!!");
      },
      enabled: Boolean(requestData),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    }
  );

  const adviceComments = useMemo(() => {
    return data ? data.data.data.attributes.adviceComments : [];
  }, [data]);

  return {
    adviceComments,
    error,
    isFetching,
  };
};

const getFileAttachment = async ({ commentId, attachementId }) => {
  try {
    const adviceId = getSoaId();

    let data = await ApiUtils.HTTPS.get(
      `/comment/${adviceId}/attachment/${commentId}/${attachementId}`
    );

    return data.data.data.attributes.url;
  } catch (err) {
    throw err;
  }
};

const deleteComment = async ({ commentId }) => {
  const adviceId = getSoaId();

  return ApiUtils.HTTPS({
    url: `/comment/${adviceId}/comment/${commentId}`,
    method: "delete",
  });
};

const useDeleteComment = ({ fetchComments }) => {
  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );

  const { mutateAsync, isLoading } = useMutation(deleteComment, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, commentId }) => {
      const comments = prevComments.filter(
        comment => comment.commentId !== commentId
      );

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...decrementCommentCount(
            summary,
            entityType,
            entityId,
            key,
            comments
          ),
        },
      });
    },
    onError: () => {
      message.error("Comment could not be deleted..!!");
    },
  });

  return [mutateAsync, { isLoading }];
};

const deleteFileAttachment = async ({ commentId, attachementId }) => {
  const adviceId = getSoaId();

  let data = await ApiUtils.HTTPS.delete(
    `/comment/${adviceId}/attachment/${commentId}/${attachementId}`
  );

  return data.data;
};

const useDeleteFileAttachment = ({ fetchComments }) => {
  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );

  const { mutateAsync, isLoading } = useMutation(deleteFileAttachment, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, commentId, attachementId }) => {
      const comments = prevComments.map(comment => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            attachments: comment.attachments.filter(
              attachement => attachement.id !== attachementId
            ),
          };
        }
        return comment;
      });

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...updateComments(summary, entityType, entityId, key, comments),
        },
      });
    },
    onError: () => {
      message.error("File could not be deleted..!!");
    },
  });

  return [mutateAsync, { isLoading }];
};

const editComment = async ({ commentId, content }) => {
  var data = {
    data: {
      id: commentId,
      type: "comment",
      attributes: {
        content,
      },
    },
  };

  const adviceId = getSoaId();

  return ApiUtils.HTTPS({
    url: `/comment/${adviceId}/comment/${commentId}`,
    method: "patch",
    data: data,
  });
};

const useEditComment = ({ fetchComments }) => {
  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );

  const { mutateAsync, isLoading } = useMutation(editComment, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, commentId, content }) => {
      const comments = prevComments.map(comment => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            content: content,
          };
        }
        return comment;
      });

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...updateComments(summary, entityType, entityId, key, comments),
        },
      });
    },
    onError: () => {
      message.error("Comment could not be edited..!!");
    },
  });

  return [mutateAsync, { isLoading }];
};

const requestConfirmation = async ({ commentId }) => {
  var data = {
    data: {
      id: commentId,
      type: "comment",
      attributes: {
        requestEdited: "true",
      },
    },
  };

  const adviceId = getSoaId();

  return ApiUtils.HTTPS({
    url: `/comment/${adviceId}/comment/${commentId}`,
    method: "patch",
    data: data,
  });
};

const useRequestConfirmation = ({ fetchComments }) => {
  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );

  const { mutateAsync, isLoading } = useMutation(requestConfirmation, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, commentId }) => {
      const comments = prevComments.map(comment => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            requestEdited: true,
          };
        }
        return comment;
      });

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...updateComments(summary, entityType, entityId, key, comments),
        },
      });
    },
    onError: () => {
      message.error("Action could not be performed..!!");
    },
    onSuccess: () => {
      notification.info({
        message: "Confirmation Requested",
        description: "You will be notified when the adviser replies",
        style: { zIndex: 99999999999 },
      });
    },
  });

  return [mutateAsync, { isLoading }];
};

const requestEditApproval = async ({ commentId, editApproved }) => {
  var data = {
    data: {
      id: commentId,
      type: "comment",
      attributes: {
        editApproved,
      },
    },
  };

  const adviceId = getSoaId();

  return ApiUtils.HTTPS({
    url: `/comment/${adviceId}/comment/${commentId}`,
    method: "patch",
    data: data,
  });
};

const useRequestEditApproval = ({ fetchComments }) => {
  const setModalValues = useSetModalValues();
  const { comments: prevComments, summary } = useGetModalValues(
    "commentsDrawer"
  );
  const { mutateAsync, isLoading } = useMutation(requestEditApproval, {
    onSettled: () => {
      fetchComments();
    },
    onMutate: ({ entityType, entityId, key, commentId, editApproved }) => {
      const comments = prevComments.map(comment => {
        if (comment.commentId === commentId) {
          return {
            ...comment,
            editApproved: editApproved,
          };
        }
        return comment;
      });

      setModalValues("commentsDrawer", {
        comments,
        summary: {
          ...updateComments(summary, entityType, entityId, key, comments),
        },
      });
    },
    onError: () => {
      message.error("Action could not be performed..!!");
    },
  });

  return [mutateAsync, { isLoading }];
};

export {
  useGetComments,
  useGetAllComments,
  useAddComment,
  useAcknowledgeComments,
  useGetCommentsSummary,
  getFileAttachment,
  useDeleteComment,
  useDeleteFileAttachment,
  useEditComment,
  useRequestConfirmation,
  useRequestEditApproval,
};
